.item-description { 
    padding: 0 1rem 1rem;

    h6 {
        color: #383838;
        font-weight: 700;
        margin: 1rem 0 0.8rem;
    }
}

.item-category {
    height: 25px;
    margin: auto 6px auto 0; 
}