.wallet {
    padding: 1rem;
    max-height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden;
    
    &__list {
        padding-left: 15px;
        @media only screen and (max-width: 1200px) {
            margin-top: 40px;
        }
    } 

    &__balance { 
        padding: 5px 10px;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img { 
            border: 1px solid #E07720;
            border-radius: 5px;
            height: auto;
            object-fit: contain;
        }

        p {
            white-space: nowrap;
            padding: 0 1rem
        }
    } 
 
    &__search { 
        position: relative;
        flex: none;
        border: 1px solid #d2d2d2; 
        width: 24rem; /* 384px */
        border-radius: 0.25rem;
        margin: 0 0.5rem; /* 8px */
        padding: 0.375rem; /* 6px */
    }
}  
