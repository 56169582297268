.location {
  &-icon {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
  
    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
    }
  
    &__text {
      position: absolute;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight: 600;
      color: #fff;
    }
  }

  &-please-select-warning {
    color: red;
    font-size: 10px;
    padding-left: 35px;
  }

  &-underline {
    border-bottom: 1px solid #c6c6c6;
    position: absolute;
    width: calc(100% - 42px);
    bottom: 0;
    right: 0.5rem;
  }

  &-underline-active {
    border-bottom: 1px solid #ED963E;
    position: absolute;
    width: calc(100% - 42px);
    bottom: 0;
    right: 0.5rem;
  }

  &-checkbox {
    display: flex;
    padding-left: 0;
    padding: 0.5rem 0;
    
    input {
      margin: 0;
      height: 20px;
      width: 20px;
      border-radius: 5px;
    }

    .form-check-label {
      font-size: 16px;
      // margin-top: 3px;
      margin-left: 40px;
    }
  }
  
  &-address {
    padding: 0.5rem 0.9rem;
    text-align: left;
    display: inline-block;
    border-radius: 0.2rem;
  }

  &-wrapper {
    width: calc(100% - 35.5px);
    display: flex;
    align-items: center;
  }

  &-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 72px;
  }
}


.add-stop {
  height: 15px;
  width: 15px;
}

.text-small {
  font-size: 14px;
}

.w-400px {
  width: 400px !important;
}

.pointer {
  cursor: pointer;
}

.pop-over-x-button {
  position: absolute;
  top: -1.3rem;
  right: -1.3rem;
  @media only screen and (max-width: 768px) {  
    top: 0;
    right: 0;
    margin: 10px;
  }

}

.destination-pop-over-style {
  border-radius: 10px;
  min-width: 400px !important;
  z-index: 1049 !important;
  background: transparent;

  @media only screen and (max-width: 1200px) {
    margin: auto !important;
  }

  .arrow::after {
    border-right-color: #E07720;
    left: -4px;
    top: -7px;
    border-width: 0.8rem 0.8rem 0.8rem 0; 
  }
}

.action-icon-container {
  width: 30px;
  height: 30px;
}

.action-icons {
  height: 25px;
  width: 25px;
}

.popover-actions {
  width: 294px;
}

.add-stop-container {
  line-height: 2;
}

.small {
  font-size: 15px;
}

.bg-selected {
  background-color: #8FB100 !important;
}

.popover-top-buttons {
  button {
    background-color: #8FB100;
    width: 100%;
    border-radius: 8px !important;
    padding: 0.7rem 0;
    border-width: 2.5px;
    // border-color: white;

    span {
      font-size: 14px !important;
    }
  }
  button:hover {
    background-color: #ED963E;
  }
  button:active {
    background-color: #ED963E !important;
  }
}


.btn-outline-light:hover {
  color: #212529;
  background-color: none ;
  border-color: none ;
}
 
.popover {
  border: none;  
}

.popover-body { 
  border-radius: 8px;
}

@media only screen and (max-width: 1200px) { 
  .popover .arrow {
    display: none;
  }

  .popover-body {
    border: 1px solid #4f4f4f;
    border-radius: 8px;
  }

  #popover-basic {
    transform: none !important;
    inset: 60px !important;
  }

  .popover-bg { 
    opacity: 0.6;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
}

@media only screen and (max-width: 768px) { 
  .popover .arrow {
    display: none;
  }

  .popover-body {
    border: 1px solid #4f4f4f;
    border-radius: 8px;
  }

  #popover-basic {
    transform: none !important;
    inset: 60px 0 !important;
    padding: 10px;
  }

  .popover-bg { 
    opacity: 0.6;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
} 