.payment-method { 
  h6 {
      color: #383838;
      font-weight: 700;
      margin: 1rem 0 0.8rem;
  }

  &__container { 
    margin: 10px 0; 
  }

  &__cash {
    cursor: pointer;
    display: flex; 
    align-items: center; 
    padding: 0.5rem;

    p {
      margin-left: 0.5rem;
    }
   
  }

  &__wallet {
    cursor: pointer;
    display: flex; 
    align-items: center;  

    p {
      margin-left: 0.5rem;
    }
   
  }
}

.check-icon {
  height: 35px;
  margin: auto;
}

.check-icon-blue{ 
  height: 25px;
  margin: auto 0;
}

.alleasy {
  height: 18px;
  margin: auto 10px;
}

.payment-icon {
  height: 28px;
  margin: auto 6px auto 0; 
}