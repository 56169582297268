.addOns {

  h5 {
    font-weight: 700;
    color: #707070;
  }
  &-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    &-checkbox {
      display: flex; 
      font-weight: 400;
      font-size: 14px;

      input {
        margin: 0;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        
        &:checked {
          &:before {
            background-color: rgb(109, 108, 106);
          }
        }
      }
      

      .form-check-label {
        // margin-top: 3px;
        color: #5a5a5a !important;
        margin-left: 30px;
      }
    }
  }
  &-subAddons {
    width: 170px;
    flex-wrap: nowrap !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    border-radius: 0;
    outline: none !important; 
    box-shadow: none !important;
    // padding: 0.3rem 1rem !important;
    white-space: nowrap;
    border-color: #707070;
    font-weight: 500;
  
   @media(max-width: 1600px){
    width: 110px;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    } 
  }

  &-subAddons1{
    border-bottom-left-radius: 10px ;
    border-top-left-radius: 10px ;
  }

  &-subAddons2{
    border-top-right-radius: 10px ;
    border-bottom-right-radius: 10px;
  }

  .btn-secondary{
    background-color: #e5e5e5;
    color: #191919;
  }
}

.electronic-switch button.ant-switch {
  background-color: #1890ff;
  background-image: none;
}