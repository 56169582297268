.suggestion-container {
  position: absolute;
  background: #fff; 
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 12px;
  top: 2.6rem;
  z-index: 999;
  width: 100%;
  overflow-y: auto;
  max-height: 340px;
}

.order-loading {
  display: flex;
  justify-content: center;
  height: 200px;
}
