.confirm_button{
    background-color: #00b4e6 !important;
    color: white !important;
    border-color: transparent;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.num_text{
   display: flex;
   justify-content:flex-start;
   align-items: center;
   font-size: 24px;
   font-weight: 500;
}

p.price{
   font-size: 30px;
   font-weight: bolder;
   color:#00b4e6;
   display: flex;
   justify-content: center;
   align-items: center;
}
.info_img{
   margin-left: 0.5rem;
   height: 30px;
   width: 30px;

}

// #price_height{
//    height: 125px;
// }

.confirm{
   padding: 15px 0 15px 0;
   display: flex ;
   justify-content: flex-end;
   align-items: center;
   // border-top: 1px solid #191919;

}

.promo_code{
   display: flex ;
   justify-content:center;
   align-items: center;
}

.input_promo{

   display: flex ;
   justify-content: flex-start;
   align-items: center;
   border-top: transparent;
   border-left: transparent;
   border-right: transparent;
   margin-right: 20px;
   width: 70%;
   border-bottom: 1px #191919 solid;
}

.validate_button{
   background-color: #ffffff;
   border: 1px solid #191919;
   color: #191919;
   font-weight: 500;
   box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2), 0 4px 6px 0 rgba(0,0,0,0.19);
}

   .validate_button:hover{
      background-color: #acacac;
      border: 1px solid #191919;

   }

.body_bg{
   background-color: #f5f5f5;
}

.standard_fair_line {
   text-decoration: line-through;
   
  text-decoration-color: #191919;
}

// Pop up
.popup {
   background-color: #fff; 
   position: absolute;
   bottom: 120%;
   left: 50%;
   border: 1px solid #cecece;
   border-radius: 5px;
   padding: 15px;
 }

.topup_order {
   position: absolute;
   top: 0px;
   right: 0px;
   z-index: 1;

   @media only screen and (max-width: 1200px) {
      display: none;
   }

   &__balance { 
      padding: 2.5px 6px;
      // border: 1px solid #ced4da;
      border-radius: 4px;
      border-radius: 0.375rem;
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      background: #fff;
      margin: 10px 60px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      img {
         //  width: 45px;
          border: 1px solid #E07720;
          border-radius: 5px;
          height: auto;
          object-fit: contain;
      } 
    }
}

.confirm-order-content {
   margin: 1rem;
   justify-content: space-between;
}