.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.swal2-select{
  border-color: #282c34; 
  border: solid 1px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=button]:focus {
  outline: none;
} 

input[type=button] {
  outline:none;
}
input[type=button]::-moz-focus-inner {
  border: 0;
}

button.black-button:disabled,
button.black-button[disabled],
button.black-button:hover:disabled,
button.black-button:hover[disabled]{ 
  background-color: #fff;
  color: #000;
  letter-spacing: 0.025em;
  border: none;
}  

button:disabled,
button[disabled],
button:hover:disabled,
button:hover[disabled]{ 
  background-color: #f3f3f3;
  color: #c0baba !important;
  letter-spacing: 0.025em;
  border: none;    
  opacity: 0.5;
}  


button:disabled.btn-disabled,
button[disabled].btn-disabled,
button:hover:disabled.btn-disabled,
button:hover[disabled].btn-disabled{ 
  background-color: #fff;
  color: rgb(0, 0, 0) !important;
  letter-spacing: 0.025em;
  border: none;  
} 

input[type=checkbox][disabled] {
  background-color: #f3f3f3;
  border: 1px solid #969696;
}
   

*:focus {
  outline: 0 !important;
} 

.icon::-webkit-input-placeholder { 
  font-family: FontAwesome, "Segoe UI", "Roboto";
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #C5C5C5
  }
  
  .icon::-moz-placeholder  { 
  font-family: FontAwesome, "Segoe UI", "Roboto";
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #C5C5C5
  }
  
  .icon:-ms-input-placeholder  { 
  font-family: FontAwesome, "Segoe UI", "Roboto";
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #C5C5C5
  }

  .row { 
    margin-left: 0 !important;
}

.background-grey {
  background-image: url('./assets/img/web/background-grey.png');
}

.background-grey:focus {
  background-image: url('./assets/img/web/background-green.png');
}

input#file{
  display:none
}

input#file + label{ 
  padding: 0.5rem;
  border: 1px solid #d2d2d2; 
  width: 100%;
  font-weight: 400;
  font-size: 0.85rem; 
  cursor: pointer
} 

Spinner, .spinner {
  color: #ed963e !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.container-fluid { 
  padding-left: 0 !important;
} 

// input[type = checkbox]:checked {
//     background-color: #ED963E !important;
//     border: 1px solid #ED963E !important; 
// }

.padding-35 {
  padding: 0.35rem;
}

select.dropdownArrow {
  width: 100%;
  padding: 0.65rem 0.5rem;
  font-size: 0.85rem;
  line-height: 1;
  border: 0;
  border-radius: 5px; 
  background: url('../src/assets/img/rental/dropdown_icon.png') no-repeat right #fff;
  -webkit-appearance: none; 
  border: 2px solid #f0f2f5;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: none !important;
}

/* table */
.maintable { 
  display: block; 
  
  @media only screen and (max-width: 1200px) {   
    display: none; 
  } 
} 

.responsive-content {
  display: none;

  .card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0.375rem;
    background-color: #fff;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 1200px) {
    display: block; 
  } 
}

.mobile-visible {
  display: none; 

  @media only screen and (max-width: 1200px) {
    display: flex; 
  } 
}

.desktop-visible {
  display: flex;

  @media only screen and (max-width: 1200px) {
    display: none;
  } 
}
 
.status {
  // Label
  &_label {
    border-radius: 5px;
    max-width: 100px;
    margin: 0 auto;
    font-weight: 600; 
  }  
  
  &_pending {
    color: #f2bb00; 
  }
  &_assigned {
    color: #e09832;
  }
  &_for_pickup {
    color: #2a2ce1;
  }
  &_on_going {
    color: #072910; 
  }
  &_picked_up {
    color: #73a920;
  }
  &_time_in {
    color: #cfc226;
  } 
  &_completed, &_delivered { 
    color: #44a7d1;
  }
  &_canceled, &_draft  {
    color: #999999; 
  }   

  // Details
  &_details {        
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 0.1rem 1rem;
    border-radius: 5px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    color: #fff; 
    
    &_pending {
      background-color: #f2bb00; 
    } 
    &_for_pickup {
      background-color: #2a2ce1; 
    }  
    &_picked_up {
      background-color: #73a920; 
    } 
    &_on_going {
      background-color: #072910; 
    } 
    &_completed, &_delivered { 
      background-color: #44a7d1;
    }
    &_canceled, &_draft  {
      background-color: #999999; 
    }   
  }
}