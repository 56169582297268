// VARIABLES // ============================== // 
$hl-color: #e07720;
$b-green: #ED963E; 
$b-lgreen: #8FB100;
$b-slgreen: #d6e2a4;
$muted-color: mix(white, #6A6A6A, 70%);
$trans-time: 300ms;
$width: 100%;

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #6c757d;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.login { 
  width: 100vw;   
  align-items: center !important;
  justify-content: center !important; 
  display: flex !important;
} 

.login-card { 
  max-width: 550px;
  max-height: 729px;
  min-width: 300px;
  flex: 1;
}

.login-logo {
  height: 100px; 

  @media only screen and (max-width: 1200px) {
    height: 60px; 
  } 
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-primary {
  outline: none;
  color: $hl-color;
  background-color: $b-green;
  border-color: $b-green;
}

.btn-primary:hover { 
  outline: none;
  color: $hl-color; 
  background-color: $b-lgreen;
  border-color: $b-lgreen;
}

.btn-secondary:disabled {
  color: $hl-color;
  background-color: $b-slgreen;
  border-color: $b-slgreen;
}

// MU 
.group {
  position: relative; 
}

// INPUTS // ============================== //

input.phone,
textarea.phone {
  background: none; 
  font-size: 20px;
  padding: 10px 8px 10px 95px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $muted-color;
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:valid ~ label { 
    top: -20px; 
    font-size: 12px;
    color: $hl-color;
  }
  &:focus ~ label.phone,
  &:valid ~ label.phone { 
    top: -20px;
    left: 0;
    font-size: 12px;
    color: $hl-color;
  }
  &:focus ~ .bar:before {
    width: $width;
  }
} 

input.password,
textarea.password {
  background: none; 
  font-size: 18px;
  padding: 8px 40px 8px 8px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $muted-color;
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:valid ~ label { 
    top: -20px; 
    font-size: 12px;
    color: $hl-color;
  }
  &:focus ~ label.phone,
  &:valid ~ label.phone { 
    top: -20px;
    left: 0;
    font-size: 12px;
    color: $hl-color;
  }
  &:focus ~ .bar:before {
    width: $width;
  }
} 

.login label {
  color: $muted-color;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none; 
  top: 10px;
  transition: $trans-time ease all;
}

.login label.phone {
  color: $muted-color;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 100px;
  top: 10px;
  transition: $trans-time ease all;
}

.bar {
  position: relative;
  display: block;
  width: $width;
  &:before {
    content: '';
    height: 1px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $hl-color;
    transition: $trans-time ease all;
    left: 0%;
  }
}

.eyes{ 
    position: absolute;
    cursor: pointer;
    right: 0px; 
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-width: 2px;
    border-color: #021d49;
    padding: 10px;  
    font-size: 20px;
}