.order-price { 
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  box-shadow: 1px -53px 125px -51px rgba(0, 0, 0, 0.75);

  &__amount {
    margin-right: 0.5rem;
    font-size: 20px;
    font-weight: 700;
    color: #2B2B2B;
    white-space: nowrap;
    cursor: pointer; 
  } 

  &__refresh {
    background-color: transparent;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  &__next {
    padding: 0.8rem 3rem;
    border-radius: 6px;
  }
}

.next-button {
  width: 370px;
}


// Pop up
.popup {
  background-color: #fff; 
  position: absolute;
  bottom: 120%;
  left: 48%;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 15px;
}