.order-details {
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        padding: 1rem 1.5rem;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh);
        position: relative;
        background-color: #fff;
        @media(max-width: 1200px){
            padding-bottom: 6rem !important;
        }
    }
    
    &-icon {
        display: flex; 
        position: relative;
    }

    &-text {
        position: absolute;
        top: 12%;
        left: 52%;
        transform: translate(-50%, -50%);
        font-size: 11px;
        font-weight: 500;
        color: #fff;
    }
}

.selectreason {
    border: 1px solid #ececec;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin-top: 0.5rem;
}