.config {
    background: #F3F3F3; 
    overflow: auto; 

    span {
        font-family: 'Montserrat', sans-serif;
    } 
    &-main{
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        display: flex;

        @media only screen and (max-width: 1200px) {   
            min-height: 0px;
            margin: 9rem 2rem 4rem;
        }
    }

    &-container {
        margin-left: 100px;
        background: #FFFFFF;
        border: 1px solid #C1272D;
        padding: 1rem;
        border-radius: 1rem;
        position: relative;
        
        @media only screen and (max-width: 1200px) { 
            margin-left: 0;
        } 
        
        &-right {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8rem;
            margin-bottom: 3.5rem;
            margin-left: 1rem; 

            @media only screen and (max-width: 1200px) { 
                margin-bottom: 0;
                margin-left: 0;
            } 

            
            &-img {
                position: absolute;
                top: -6.5rem;
                height: 16rem;

                @media only screen and (max-width: 1200px) {  
                    height: 14rem;
                }
            }
    
        } 
        &-403 {
            color: #C1272D;
            font-weight: 700;
            font-size: 5rem; 
        }
    }

    &-img-top {
        position: absolute;
        height: 20rem;
        right: 0px;
        top: 0px;
    }

    &-bottom { 
        position: fixed;  
        margin-left: 100px;
        left: 0px;
        bottom: 0px; 
        background: #A1191E; 
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        padding: 0.25rem;
        width: 100%;

        @media only screen and (max-width: 1200px) { 
            margin-left: 0;
        }

        span {     
            color: #FFFFFF;
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding: 0 0.5rem; 
        }
    }
}

.maintenance {
    background: #C1272D;
    overflow: auto;
    z-index: 100;
    height: 100%; 

    @media only screen and (max-width: 768px) {
        padding-top: 2rem;  
    }

    &-main{
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        display: flex;

        @media only screen and (max-width: 1200px) {   
            min-height: 0px;
        }
        
        &-sub { 
            margin-top: 0;
            margin-bottom: 0;
    
            @media only screen and (max-width: 1200px) {    
                margin-top: 2rem;
                margin-bottom: 3rem;
            } 
        }
    }

    span {
        font-family: 'Montserrat', sans-serif;
    } 

    &-union{  
        position: absolute;    
        top: -2rem;
        bottom: 0px;
        height: 22rem;
        left: -8.5rem;
        display: block;

        @media only screen and (max-width: 1200px) {   
            display: none;
          } 
    }

    &-container { 
        background: #F3F3F3;
        border: 1px solid #C1272D;
        padding: 1rem;
        border-radius: 1rem;
        position: relative;    
        margin-top: 7rem;    
        padding: 1rem 6rem 2rem;

        @media only screen and (max-width: 1200px) {   
            padding: 1rem rem 6rem;
        } 

        @media only screen and (max-width: 768px) {   
            padding: 1rem 0 4rem;  
        } 
        
        &-p {
            margin: 1.5rem 0; 

            p {
                font-size: 14px;
                color: #6c6c6c;
                font-family: 'Montserrat', sans-serif;

                @media only screen and (max-width: 768px) {
                font-size: 12px;
                }
            }

            
            @media only screen and (max-width: 1200px) {   
                margin: 2rem 0;
            } 
  
            @media only screen and (max-width: 768px) {
                padding: 1rem 0 2rem; 
            }
        }

        &-img {
            position: absolute;
            top: -8rem;
            height: 15rem;

            @media only screen and (max-width: 768px) {
                top: -5rem;
                height: 10rem;
            }
        } 

        &-tools {
            height: 6.5rem;

            @media only screen and (max-width: 768px) {
                margin: auto;
                height: 3.8rem;
            }
        }

        
        &-tool1 {
            position: absolute;
            bottom: -0.5rem;
            left: 2rem;
            height: 42px;
        }

        &-tool2 {
            position: absolute;
            bottom: -0.35rem;
            right: 2rem;
            height: 70px;
        }
    }
 
    &-bottom { 
        // position: absolute;   
        left: 0px;
        bottom: 0px; 
        background: #A1191E; 
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        padding: 0.25rem;
        width: 100%;
        position: fixed;
 

        span {     
            color: #FFFFFF;
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding: 0 0.5rem; 
        }

        @media only screen and (max-width: 1200px) { 
            margin-left: 0; 
        }
    }
}