Table {
    border-spacing: 0;
    margin-top: 10px;
    text-align: center; 
    border-radius: 10px;
}

.table-list {
  border-spacing: 0;
  margin-top: 10px;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;

  &-header {
    &__row {
      &-cell {
        color: #818181;
        font-size: 15px;
        border: none !important;
        // background-color: #00b4e6;
      }
    }
  }

  &-body {
    &__row {
      &-cell {
        td {
          margin: 0;
          padding: 1rem;
          font-size: 16px;
          border: 1px solid #999;
        }
      }
    }
  }

  &-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button:disabled,
    button[disabled],
    button:hover:disabled,
    button:hover[disabled]{
      color: rgb(0, 0, 0);
    }

    &__actions {
      font-weight: 700;
    }
    &__disabled {
      background-color: #e9e9e9 !important;
      color: #707070 !important;
      border: none !important;
    }
  }  
}

.table-bordered th, .table-bordered td{
  border: none !important;
} 