.order {
  &-container { 
    right: 0;
    left: 0;
    max-height: calc(100vh - 85px);
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: 1200px) {
      max-height: calc(100vh - 0px);
      padding-top: 55px;
    }
  }
}

.select {
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 10;
  width: 200px;
}

.left-column {
  height: 100%;

  @media only screen and (max-width: 1200px) { 
    height: 100vh
  }
}  

 