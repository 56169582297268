// VARIABLES // ============================== // 
$hl-color: #5e367c;
$b-green: #ED963E; 
$b-lgreen: #8FB100;
$b-slgreen: #d6e2a4;
$muted-color: mix(white, #6A6A6A, 70%);
$trans-time: 300ms;
$width: 100%;
 
.date-picker {
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.9);
  top: 0; 

  .rdtPicker {
    width: 374px !important;
    position: relative;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  th {
    padding: 15px;
    font-size: 16px;
  }
  td {
    padding: 15px;
    font-size: 16px;
  }
}

.w-250 {
  width: 374px;
}


.schedule-selection {
  &__label {
    font-weight: 500 !important;
  }
  &__date {
    border-top: 1px solid white;
    background-color: #ED963E;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;  
    padding: 0.7rem 0.5rem;
    strong { 
      color: #fff;
    }
  }
  &__dateTime {
    width: 374px;
    input {
      border-radius: 0;
      text-align: center;
    }
  }
}
.react-time-picker {
  &__wrapper {
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
  }
  &__inputGroup {
    &__divider {
      padding: 1rem 0.5rem;
    }
    &__hour, &__minute {
      font-size: 25px !important;
      margin: 0 5px !important;
      padding: 5px !important;
      border-radius: 5px !important;
      border: 1px solid #8f8f8f !important;
    }
    &__leadingZero {
      display: none;
    }
  }
}

.btn-primary {
  outline: none !important;
  color: $hl-color !important;
  background-color: $b-green !important;
  border-color: $b-green !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active { 
  outline: none !important;
  color: $hl-color !important; 
  background-color: $b-lgreen !important;
  border-color: $b-lgreen !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #ED963E !important;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgb(255 255 255 / 25%) !important;
}

.black {
  color: "#1a1a1a";
}

.white {
  color: "#fff";
}
