.promo-code {
  margin-top: 1rem;
  padding: 0 0 1rem;

  h6 {
    color: #383838;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }

  &__input {
    padding: 0.5rem 0 0.5rem;
    width: 90%;
    outline: none;
    border:none;
    border-bottom: 1px solid #191919;
  }

  &__button {
    background-color: #fff !important;
    color: #191919 !important;
    border: none; 
  }
}