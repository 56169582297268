@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); 
@import url('https://netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css'); 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 

p {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
} 

select {
  outline: none !important;
}

img, video {
  max-width: none;
  height: none;
}

.border-maincolor {
  border: 1px solid #ff7f14;
}

@media(max-width: 768px){ 
  .gm-bundled-control-on-bottom {
    bottom: 0px !important;
    top: 50px;
  } 
} 

.react-datepicker__close-icon::after { 
  background-color: #959595 !important;
  font-size: 10px !important;
}