.vehicle-selection {
  &-icon {
    display: flex;
    align-items: center;
    p {
      margin: 0 1.5rem;
    }
    &__container {
      width: 50px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
      
    }
  }
  &-label {
    font-weight: 600;
    &__selected {
      margin-left: 1rem !important;
      font-weight: 600;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  background-color: black;
  border-radius: 50%;
  color: white;
}

.iconRoundFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe200;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.vehicle {
  &_img {
    margin: 5px;
    width: 70px;
    height: 100%; 
    cursor: pointer;
  }
  &-container {
    display: flex;  
    justify-content: unset; 
    margin: auto;    
    overflow-x: auto;
    padding: 0.5rem; 
    //   overflow-x: scroll;
    //   overflow-y: hidden;
      scrollbar-width: thin;
      scrollbar-color: #888 transparent;
 
    @media only screen and (max-width: 780px) {   
      justify-content: flex-start;
      overflow-x: auto; 
    }

    @media only screen and (max-width: 360px) {   
      justify-content: flex-start;
        overflow-x: scroll; 
    }
     

    &::-webkit-scrollbar {
      width: 15px; 
      height: 9px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background:#ed963e; 
      border-radius: 10px; 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: grey; 
    }
  }
}

