.order-details {
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        padding: 1rem 1.5rem;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh);
        position: relative;
        background-color: #f9f9f9; 
        right: 0;
        left: 0;
        @media(max-width: 1200px){
            padding-bottom: 6rem !important;
        }
    }
}


.order-details-side {
    max-height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden; 
    border-left: 1px solid #d2d2d2;
    background-color: #fff;
    z-index: 10;
} 

.order-details-border {
    border: 1px solid #ED963E;
}
 