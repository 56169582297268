.modal_terms { 
    &_body {  
        padding: 1.5rem 4rem 0;
        height: 500px;
        overflow-y: scroll;
        scrollbar-width: thin; 

        /* width */
        &::-webkit-scrollbar {
            width: 15px; 
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background:#ed963e;
            // rgb(157, 156, 156); 
            border-radius: 10px; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: grey; 
        }
    }

    &_close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 2rem;
        color: #e17d2a;
        margin: auto 0;
        cursor: pointer;
    }
}
