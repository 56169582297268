.order-details {
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        padding: 1rem 1.5rem;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh);
        position: relative;
        background-color: #f9f9f9; 
        right: 0;
        left: 0;
        @media(max-width: 1200px){
            padding-bottom: 6rem !important;
        }
    }
}


.order-details-side {
    max-height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden; 
    border-left: 1px solid #d2d2d2;
    background-color: #fff;
    z-index: 10;
} 

.order-details-border {
    border: 1px solid #ED963E;
}

.thirdparty {
    background-color: #e5f7ff;
    border: 1px solid #2db7f5;
    border-radius: 4px;
    margin: 6px;
    padding: 2px 5px; 
    color: #2db4f0;
    font-size: 14px;
}
 

.order-details {
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        padding: 1rem 1.5rem;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh);
        position: relative;
        background-color: #fff;
        @media(max-width: 1200px){
            padding-bottom: 6rem !important;
        }
    }
    
    &-icon {
        display: flex; 
        position: relative;
    }

    &-text {
        position: absolute;
        top: 12%;
        left: 52%;
        transform: translate(-50%, -50%);
        font-size: 11px;
        font-weight: 500;
        color: #fff;
    }
}

ul { 
    &.timeline { 
        list-style-type: none;
        position: relative;
        margin-top: 1rem; 
        z-index: 0;

        &:before {
            content: ' ';
            background: #d4d9df;
            display: inline-block;
            position: absolute;
            left: 29px;
            width: 2px;
            height: 100%;
            z-index: 400;
        }

        & > li {
            margin-bottom: 25px;
            padding-left: 50px;

            &:before {
                content: ' ';
                background: white;
                display: inline-block;
                position: absolute;
                border-radius: 50%;
                border: 2px solid #ec953e;
                left: 20px;
                width: 20px;
                height: 20px;
                z-index: 400;
            }
        }
    }
} 
 
.modal_rental { 
    &_header {
        padding: 1rem; 
        white-space: normal;
        border-bottom: 1px solid #d2d2d2;
        justify-content: space-between;
        display: flex;
    } 

    &_body {  
        padding: 1rem;
        height: 500px;
        overflow-y: scroll;
        scrollbar-width: thin; 

        /* width */
        &::-webkit-scrollbar {
            width: 15px; 
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(157, 156, 156); 
            border-radius: 10px; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: grey; 
        }
    }

    &_close {
        font-size: 2rem;
        color: #e17d2a;
        margin: auto 0;
        cursor: pointer;
    }
}

