.vehicle-selection {
  &-icon {
    display: flex;
    align-items: center;
    p {
      margin: 0 1.5rem;
    }
    &__container {
      width: 50px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
      
    }
  }
  &-label {
    font-weight: 600;
    &__selected {
      margin-left: 1rem !important;
      font-weight: 600;
    }
  }
  
  &-container { 
      display: flex;
      justify-content: center;
      margin: 0.5rem 0.25rem; 
      overflow-y: hidden;
      overflow-x: auto;

    @media only screen and (max-width: 1200px) {
      overflow-y: hidden;
      overflow-x: auto;
      margin: 0 1rem;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  background-color: black;
  border-radius: 50%;
  color: white;
}

.iconRoundFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe200;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.vehicle_img {
  margin: 5px;
  width: 55px;
  height: 100%; 
  cursor: pointer;
}