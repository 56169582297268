.rental-list {
    padding: 1rem;
    max-height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden;
  
    &__title {
      font-weight: 700;
    }
  
    &__container {
      width: 100%;
      margin: 0 1rem;
    }
  
    &__wrapper {
      width: 75%;
      margin: 0 1rem;
    }
    &__filter {
      padding-top: 10px;
      background-color: #ffffff;
      border: #999999 0.3px solid;
      border-radius: 15px;
      margin: 10px;
    }
    &__label {
      font-size: 14px;
      margin-bottom: 5px;
    }
  
    &__action { 
      outline: none !important;
    }
  
    &__image {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }
  
    &__expand {
      background-color: #fbe6af;
      padding: 10px;
    }
  
    &-order_number {
      cursor: pointer;
    }
    &-order_number:hover {
      text-decoration: underline;
    }
  
    [class*="btn-primary"] {
      &:disabled {
        background: #808080;
        border: 1px solid #808080;
      }
    }
  } 
  
  .form-control{
    border: none;
  }
  
  .form-control:focus{
    border-color: none;
    box-shadow: none;
  }
  
  .css-yk16xz-control{
    border: none;
  } 
  .css-yk16xz-control {
    border: none !important;
  }
  
  .css-1pahdxg-control, .css-1pahdxg-control:hover, .css-yk16xz-control  { 
    border: none !important;
    box-shadow: none !important; 
    outline: none !important;
  } 
  
  .css-tlfecz-indicatorContainer, .css-tlfecz-indicatorContainer:hover {
    color: #595858 !important;
  }
  
  .css-1okebmr-indicatorSeparator {
    background-color: #fff !important;
  }
  
  .order {
    padding-left: 15px;
    padding-right: 15px;
  
    &__grid7 {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 0.5rem/* 8px */;
      margin-top: 2.5rem/* 40px */;
  
      @media only screen and (max-width: 1200px) {   
        display: block;
      }  
  
      .filter {
        grid-column-end: 4 !important;
        grid-column: span 3 / span 3;
        display: flex;
  
        @media only screen and (max-width: 1200px) {   
          grid-column: 0;
          grid-column: 0; 
        } 
  
        .search {
          grid-column-start: 1;
          grid-column-end: 3;
          border-radius: 0.25rem/* 4px */;
          border: 1px solid #d2d2d2;
          width: 100%;
    
          @media only screen and (max-width: 1200px) {   
            display: none;
          } 
        }
    
        .status {
          display: flex;
          width: 20rem;
          border-radius: 0.25rem/* 4px */;
          border: 1px solid #d2d2d2;
          padding: 0.5rem 0.25rem;
          margin: 0 0.50rem;
    
          @media only screen and (max-width: 1200px) {   
            width: 100%; 
          } 
        }
      }
  
        .datefilter {  
          grid-column-end: 7;
          grid-column-start: 5;
          padding: 0.5rem 0;
          border-radius: 0.25rem/* 4px */;
          border: 1px solid #d2d2d2;
  
          @media only screen and (max-width: 1200px) {   
            display: none;
          } 
        }
    }
  
    
  }

  .rental-sub-categories {
    border-bottom: 1px solid #cecece;
    display: flex;
    margin-top: 10px;
  }
   