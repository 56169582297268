.order-list {
  padding: 1rem;
  max-height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;

  &__title {
    font-weight: 700;
  }

  &__container {
    width: 100%;
    margin: 0 1rem;
  }

  &__wrapper {
    width: 75%;
    margin: 0 1rem;
  }
  &__filter {
    padding-top: 10px;
    background-color: #ffffff;
    border: #999999 0.3px solid;
    border-radius: 15px;
    margin: 10px;
  }
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__action { 
    outline: none !important;
  }

  &__image {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }

  &__expand {
    background-color: #fbe6af;
    padding: 10px;
  }

  &-order_number {
    cursor: pointer;
  }
  &-order_number:hover {
    text-decoration: underline;
  }

  &-sub-categories {
    border-bottom: 1px solid #cecece;
    display: flex;
    margin-top: 10px;
  }

  [class*="btn-primary"] {
    &:disabled {
      background: #808080;
      border: 1px solid #808080;
    }
  }
} 

.form-control{
  border: none;
}

.form-control:focus{
  border-color: none;
  box-shadow: none;
}

.css-yk16xz-control{
  border: none;
}
 
.line { 
  position: relative;
  padding: 10px;
  z-index: -1;
}

.bottom:before {
  content: "";
  position: absolute;
  left:30px;
  bottom: 0;
  height: 15px;
  width: 55%;
  border-bottom: 2px solid #E07720;
} 

.noline { 
  padding: 10px;
}

.line:hover, .noline:hover {
  color: #5E367C;
}

.link, .link:hover{
  color: #464646 !important;
} 

.css-yk16xz-control {
  border: none !important;
}

.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-yk16xz-control  { 
  border: none !important;
  box-shadow: none !important; 
  outline: none !important;
} 

.css-tlfecz-indicatorContainer, .css-tlfecz-indicatorContainer:hover {
  color: #595858 !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: #fff !important;
}

.order {
  padding-left: 15px;
  padding-right: 15px;

  &__grid7 {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 0.5rem/* 8px */;
    margin-top: 1rem;

    @media only screen and (max-width: 1200px) {   
      display: block;
    }  

    .filter {
      grid-column-end: 4 !important;
      grid-column: span 3 / span 3;
      display: flex;

      @media only screen and (max-width: 1200px) {   
        grid-column: 0;
        grid-column: 0; 
      } 

      .search-filter {
        grid-column-start: 1;
        grid-column-end: 3; 
        display: flex;
        border: 1px solid #d2d2d2;
        width: 100%;    
        margin: 1rem 0;
  
        @media only screen and (max-width: 1200px) {   
          display: none;
        } 
      }
  
      
      .status-filter {
        grid-column-start: 1;
        grid-column-end: 3;
        display: flex;
        width: 100%; 
        border: 1px solid #d2d2d2;
        padding: 0.5rem 0.25rem; 
        margin: 1rem 0;
      } 

      .filter-categories {
        display: flex;
        width: 20rem; 
        border-radius: 0.25rem 0 0 0.25rem;
        border: 1px solid #d2d2d2;
        border-right: none;
        padding: 0.5rem; 
        margin: 1rem 0;
  
        @media only screen and (max-width: 1200px) {   
          width: 100%; 
        } 
      }
    }

      .datefilter {  
        grid-column-end: 8;
        grid-column-start: 5;
        padding: 0;
        border-radius: 0.25rem/* 4px */; 
        border: none !important;

        @media only screen and (max-width: 1200px) {   
          display: none;
        } 
      }
  } 
} 
 

.download-btn {  
  border: none;
  border-radius: 0.375rem; 
  color: #fff !important;
  text-align: center !important; 
  padding: 1rem 1.5rem !important;
  margin: 1rem 0.5rem !important;
  background-color: #ED963E;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  letter-spacing: 0.05em; 
  font-weight: 500;
  letter-spacing: 0.1rem;

  :focus {
    outline: none;
  }
}


.filter-date-btn {  
  border: none;
  border-radius: 0 0.375rem 0.375rem 0;
  color: #fff !important;
  text-align: center !important;
  padding: 1rem 1.25rem!important;
  margin: 1rem 0 !important;
  background-color: #ED963E;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  letter-spacing: 0.05em; 
  font-weight: 500;
  letter-spacing: 0.1rem;

  :focus {
    outline: none;
  }

  :disabled {
    background-color: #fff;
    color: #000;
    letter-spacing: 0.025em;
    border: none;
  }
}



.datepicker-list { 
  border: 1px solid #d2d2d2;
  width: 100%;
  border-radius: 0.375rem;  
} 