.suggestion-items {
  z-index: 5123123100;
}

.autocomplete {
  &-menu {
    margin-top: 0.5rem;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 12px;
    background: rgb(255, 255, 255);
    padding: 2px 0px;
    font-size: 90%;
    position: absolute; 
    overflow-y: scroll;
    width: 110%;
    max-width: 120%;
    left: -1rem;
    max-height: 500%;
    z-index: 999;
  }

  &-favorite {
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 12px;
    background: rgb(255, 255, 255);
    padding: 2px 0px;
    font-size: 90%;
    position: fixed;
    overflow: auto;
    max-height: 50%;
    max-width: 50%;
    z-index: 1000;
  }

  &-input {
    cursor: pointer; 
    color: #191919; 
    border: none;
    padding: 0;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.bg-highlighted { 
  --tw-bg-opacity: 1;
  background-color: #95959524;
}