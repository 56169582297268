// Schedule Pickup
.rdrCalendarWrapper {
    font-size: 14px !important;
    width: 374px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
 
.rdrDateDisplayWrapper {
    display: none;
}

.rdrNextPrevButton {
    background: transparent;
}

.rdrNextPrevButton:hover {
    background: #EFF2F7;
} 

.rdrEndEdge, .rdrStartEdge {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    left: 0 !important; 
    right: 0 !important;
} 

.rdrDayStartPreview rdrDayEndPreview, .rdrDayEndPreview,
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: none !important; 
    right: 0 !important;
    left: 0 !important; 
    bottom: 0 !important;
    top: 0 !important; 
} 

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: #ff7f14;
}

.rdrDayStartPreview rdrDayEndPreview, .rdrDayEndPreview {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: none !important; 
    right: 0 !important;
    left: 0 !important; 
    bottom: 0 !important;
    top: 0 !important; 
}

.rdrEndEdge, rdrStartEdge {
    border-radius: none !important;
    color: #E07720 !important;
} 

.rdrDayToday .rdrDayNumber span::after {
    content: none;
}

.rdrDayToday .rdrDayNumber span:before {
    content: '';
    position: absolute;
    bottom: 4px; 
    background: #fff;
    display: inline-block;
    border-left: 7px solid transparent; 
    position: absolute;
    bottom: 4px;
    right: 4px; 
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    border: none !important;
    color: #000 !important;
    // background-color: #E07720;
}  

.rdrMonth {
    width: 100% !important;
}