.items {
    padding: 20px 0;
}

  .card-image {
    width: 100%;

    img {
      object-fit: cover;
      width: 100%; 
      height: 100%;

      &:hover {
        background: rgba(0, 0, 0, 0.662);
      }
    } 
}

.ant-card-body {
  padding: 15px;
  border: 1px solid #cecece; 
  border-radius: 0 2px 2px 0;
}   

.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
 

.ant-card {
  font-feature-settings: "tnum","tnum";
  background: #fff;
  border-radius: 2px; 
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.ant-typography {
  color: rgba(0,0,0,.85);
  overflow-wrap: break-word; 
}

.ant-card-meta-description {
  color: rgba(0,0,0,.45);
}

.link-attachment {
  color: #f79533;

  &:hover { 
    color: #ff7f14;
  }
} 

.schedule-highlight {
  padding: 2px 10px;
  color: white;
  background-color: #ff7f14;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
}

